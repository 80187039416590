import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MainService } from 'src/app/provider/main.service';

@Component({
  selector: 'app-view-category',
  templateUrl: './view-category.component.html',
  styleUrls: ['./view-category.component.css']
})
export class ViewCategoryComponent implements OnInit {
  categoryId: any;
  user: any;
  profile: any;

  constructor(private activate:ActivatedRoute,private route:Router,public mainService: MainService) { }

  ngOnInit() {
    this.activate.paramMap.subscribe(params => {
      this.categoryId = params.get('id');
      this.vewCategory()
      console.log("categoryId for view",this.categoryId);
    });
  }

  vewCategory(){
    this.mainService.showSpinner();

    this.mainService.getApi('admin/viewProductCagtegory?categoryId='+this.categoryId,1).subscribe((res: any) => {
      if (res.responseCode == 200 && res.result) {
        this.user = res.result;
        this.profile= this.user.categoryImage;
        this.mainService.hideSpinner();
        this.mainService.successToast(res.responseMessage)
       } else {
        this.mainService.hideSpinner();
        this.mainService.errorToast(res.responseMessage)
      }
      error => {
        this.mainService.hideSpinner();
        this.mainService.errorToast(error.responseMessage)
      }
    })
  }

  
  navigateTo(){
    this.route.navigate(['/product-management'],{queryParams:{navFrom:'CATEGORY'}})
  }
}
