import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ngxCsv } from 'ngx-csv/ngx-csv';
import { MainService } from 'src/app/provider/main.service';
declare var $: any;

@Component({
  selector: 'app-product-management',
  templateUrl: './product-management.component.html',
  styleUrls: ['./product-management.component.css']
})
export class ProductManagementComponent implements OnInit {
  categoryList: any = [];
  addSubForm: FormGroup;
  profile: any;
  search: string;
  productlists: any = [];
  limit: number = 5;
  currentPage = 1;
  productId: any;
  itemPerPage = 5;
  p: any = 0;
  status: any;
  ProductLenght: any = 0;
  currTab: any = 'Product';
  totalRecord: any = 0;
  CategorySearch: any;
  categoryId: any;
  crr: any = []
  brr: any = []
  constructor(private router: Router, private activatedRoute: ActivatedRoute, public mainService: MainService) {


  }

  ngOnInit() {
    this.exportMethod()
    this.exportcat()
    this.activatedRoute.queryParams.subscribe(res => {
      console.log(res)
      if (res.navFrom == 'CATEGORY') {
        this.selectTab('Category')
      } else {
        if (this.currTab === 'Product') {
          this.selectTab('Product')
        }

      }
    })

    this.addSubForm = new FormGroup({
      "categoryName": new FormControl('', Validators.required),
    });
  }


  // =========tab link====//
  selectTab(tab) {
    this.currTab = tab;
    console.log(`You are in ${this.currTab} Tab`);
    if (this.currTab === 'Product') {
      this.productList();
    }
    else if (this.currTab === 'Category') {
      this.getPdttcategoryList()
    }
  }

  resetProduct() {
    this.search = ''
    this.productList();
  }

  addModal() {
    $('#addSub').modal('show')
  }

  handleInputChange(e) {
    var file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];

    var reader = new FileReader();
    reader.onload = this._handleReaderLoaded.bind(this);
    reader.readAsDataURL(file);
  }
  _handleReaderLoaded(e) {
    let reader = e.target;
    this.profile = reader.result;
    console.log("profile", this.profile)
  }

  searchValue() {
    this.currentPage = 1;
    this.ProductLenght = 0;
    this.mainService.showSpinner();
    let object = {
      "search": this.search,
      "page": this.currentPage,
      "limit": this.limit
    }

    this.mainService.postApi('admin/productList', object, 1).subscribe(res => {
      console.log(" productList==>", res)
      if (res.responseCode == 200) {
        this.mainService.hideSpinner();
        this.mainService.successToast(res.responseMessage)
        this.productlists = res.result.docs
      } else {
        this.mainService.hideSpinner();
        this.mainService.errorToast(res.responseMessage)
      }
      error => {
        this.mainService.hideSpinner();
        this.mainService.errorToast(error.responseMessage)
      }
    })
  }
  exportCSV() {
    {
      let dataArr = [];
      this.crr.forEach((element, ind) => {
        dataArr.push({
          'S.No.': ind + 1,
          'Product Name': element.productName ? element.productName : '--',
          'Charges': element.price ? element.price : '--',
          'Used For': element.usedFor ? element.usedFor : '--',
          'Service Type': element.type ? element.type : '--',
          'Status': element.status ? element.status : '--',
        })
      })
      this.mainService.exportAsExcelFile(dataArr, 'Product_management');
    }
  }

//product
exportMethod(){
   
  let data = {
    'page': this.currentPage,
    'limit':  500,
  
    
  }

  this.mainService.postApi('admin/productList', data, 1).subscribe((res: any) => {

    this. crr = res.result.docs;
    console.log(this.crr )
  },
  )}

  productList() {
    this.mainService.showSpinner();

    let object = {
      "page": this.currentPage,
      "limit": this.limit
    }
    this.mainService.postApi('admin/productList', object, 1).subscribe(res => {
      console.log(" productList==>", res)
      if (res.responseCode == 200 && res.result && res.result.docs) {

        this.productlists = res.result.docs
        this.ProductLenght = res.result.total;
        this.mainService.hideSpinner();
        this.mainService.successToast(res.responseMessage)


      } else {
        this.mainService.hideSpinner();
        this.mainService.errorToast(res.responseMessage)
      }
      error => {
        this.mainService.hideSpinner();
        this.mainService.errorToast(error.responseMessage)
      }
    })
  }
  deleteFunction(id) {
    this.productId = id
    console.log('delete modal', this.productId)
    $('#deleteModal').modal({ backdrop: 'static', keyboard: false })
  }
  deleteUser() {
    this.mainService.showSpinner()
    let object = {
      'productId': this.productId
    }

    this.mainService.deleteApi('admin/deleteProduct', object, 1).subscribe(res => {
      console.log('delete id=========>', res)
      if (res.responseCode == 200) {
        this.productList()
        this.mainService.hideSpinner()
        $('#deleteModal').modal('hide');
        this.mainService.successToast(res.responseMessage)

      } else {
        this.mainService.hideSpinner()
        this.mainService.errorToast(res.responseMessage)
      }
    }, error => {
      this.mainService.hideSpinner()
      this.mainService.errorToast(error.responseMessage)
    })
  }
  blockFunction(id, status) {
    this.productId = id
    if (status == "ACTIVE") {
      this.status = "BLOCK"
    }
    else {
      this.status = "ACTIVE"
    }
    console.log('Block Modal', this.productId)
    $('#blockModal').modal({ backdrop: 'static', keyboard: false })
  }
  blockUser() {
    this.mainService.showSpinner()
    let data = {
      'productId': this.productId
    }

    this.mainService.postApi('admin/blockUnblockProduct', data, 1).subscribe(res => {
      console.log('block id=========>', res)
      if (res.responseCode == 200) {
        this.productList()
        this.mainService.hideSpinner()
        $('#blockModal').modal('hide');
        this.mainService.successToast(res.responseMessage)
      } else {
        this.mainService.hideSpinner()
        this.mainService.errorToast(res.responseMessage)
      }
    }, error => {
      this.mainService.hideSpinner()
      this.mainService.errorToast(error.responseMessage)
    })

  }
  pagination(event) {
    console.log('This event will display page number:->', event);
    this.currentPage = event;
    this.productList()
  }
  deleteMultiUser() {

  }


  //---------category list api -------------//
  getPdttcategoryList() {
    let object = {
      "page": this.currentPage,
      "limit": this.limit
    }
    this.mainService.postApi('admin/productCategoryList', object, 1).subscribe(res => {
      console.log(" productList==>", res)
      if (res.responseCode == 200 && res.result && res.result.docs) {
        this.categoryList = res.result.docs;
        this.totalRecord = res.result.total;
        this.mainService.hideSpinner();
      } else {
        this.mainService.hideSpinner();
        this.mainService.errorToast(res.responseMessage)
      }
      error => {
        this.mainService.hideSpinner();
        this.mainService.errorToast(error.responseMessage)
      }
    })
  }
  paginationCtegory(event) {
    this.currentPage = event;
    this.getPdttcategoryList()
  }


  searchCategory() {
    this.currentPage = 1;
    this.totalRecord = 0;
    this.mainService.showSpinner();
    let object = {
      "search": this.CategorySearch,
      "page": this.currentPage,
      "limit": this.limit
    }

    this.mainService.postApi('admin/productCategoryList', object, 1).subscribe(res => {
      console.log(" productList==>", res)
      if (res.responseCode == 200) {
        this.mainService.hideSpinner();
        this.mainService.successToast(res.responseMessage)
        this.categoryList = res.result.docs
      } else {
        this.mainService.hideSpinner();
        this.mainService.errorToast(res.responseMessage)
      }
      error => {
        this.mainService.hideSpinner();
        this.mainService.errorToast(error.responseMessage)
      }
    })
  }

  resetCategory() {
    this.CategorySearch = ''
    this.getPdttcategoryList()
  }

  CategoryexportCSV() {
    {
      let dataArr = [];
      this.brr.forEach((element, ind) => {
        dataArr.push({
          'S.No.': ind + 1,
          'Category Name': element.categoryName ? element.categoryName : '--',
          'Status': element.status ? element.status : '---'
        })
      })
      this.mainService.exportAsExcelFile(dataArr, 'Category_management');
    }
  }
  exportcat(){
    let data = {
      'page': this.currentPage,
      'limit':  500,
    
      
    }
  
    this.mainService.postApi('admin/productCategoryList', data, 1).subscribe((res: any) => {
  
      this. brr = res.result.docs;
      console.log(this.brr )
    },
    )}
  

  addProductCategory() {
    let url = 'admin/addProductCategory'

    let data =
    {

      'categoryName': this.addSubForm.value.categoryName,
      'categoryImage': this.profile,
    }
    this.mainService.showSpinner();
    this.mainService.postApi(url, data, 1).subscribe((res: any) => {
      console.log("addProduct response ==>", res)
      if (res.responseCode == 200 && res.result) {
        this.mainService.hideSpinner();
        this.mainService.successToast(res.responseMessage)
        $('#addSub').modal('hide');
        this.getPdttcategoryList()
      } else {
        this.mainService.hideSpinner();
        this.mainService.errorToast(res.responseMessage)
      }
      error => {
        this.mainService.hideSpinner();
        this.mainService.errorToast(error.responseMessage)
      }
    })


  }


  deleteCategory(id) {
    this.categoryId = id
    console.log('delete modal', this.categoryId)
    $('#CategorydeleteModal').modal({ backdrop: 'static', keyboard: false })
  }
  deleteUserCategory() {
    this.mainService.showSpinner()
    let object = {
      'categoryId': this.categoryId
    }

    this.mainService.deleteApi('admin/deleteProductCategory', object, 1).subscribe(res => {
      console.log('delete id=========>', res)
      if (res.responseCode == 200) {
        this.getPdttcategoryList()
        this.mainService.hideSpinner()
        $('#CategorydeleteModal').modal('hide');
        this.mainService.successToast(res.responseMessage)


      } else {
        this.mainService.hideSpinner()
        this.mainService.errorToast(res.responseMessage)
      }
    }, error => {
      this.mainService.hideSpinner()
      this.mainService.errorToast(error.responseMessage)
    })
  }

}
