import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MainService } from 'src/app/provider/main.service';

@Component({
  selector: 'app-home-banner-add',
  templateUrl: './home-banner-add.component.html',
  styleUrls: ['./home-banner-add.component.css']
})
export class HomeBannerAddComponent implements OnInit {
  marked = false;
  theCheckbox = false;
  addBannerForm: FormGroup;
  config = {
    uiColor: '#F0F3F4',
    height: '40%',
    enterMode: 2,
    autoParagraph: false
  };

  imgBase64Path: string;

  constructor(public mainService: MainService, private router: Router) { }

  ngOnInit() {
    this.addBannerForm = new FormGroup({
      'title': new FormControl('', [Validators.required, Validators.pattern(/^[a-zA-Z ]*$/i)]),
      "description": new FormControl('', Validators.required),
      "bannerFile": new FormControl('', Validators.required)
    });
  }
  addNewBanner() {
    if (!this.imgBase64Path) {
      return this.mainService.errorToast('Please upload image.')
    }
    let url = 'admin/addHomeScreen'
    let data = {
      'active': this.marked,
      'title': this.addBannerForm.value.title,
      'description': this.addBannerForm.value.description,
      'image': this.imgBase64Path,
    }
    console.log(data)
    this.mainService.showSpinner();
    this.mainService.postApi(url, data, 1).subscribe((res: any) => {
      console.log("AddBanner  response ==>", res)
      if (res.responseCode == 200 && res.result) {
        this.mainService.hideSpinner();
        this.mainService.successToast(res.responseMessage)
        this.router.navigate(['/home-banner'])
        this.addBannerForm.reset()
      } else {
        this.mainService.hideSpinner();
        this.mainService.errorToast(res.responseMessage)
      } error => {
        this.mainService.hideSpinner();
      }
    })
  }

  // upload image 
  fileChangeEvent(fileInput: any) {
    if (fileInput.target.files && fileInput.target.files[0]) {
      const max_height = 450;
      const max_width = 1920;
      const max_width2 = 1640;
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const image = new Image();
        image.src = e.target.result;
        image.onload = rs => {
          const img_height = rs.currentTarget['height'];
          const img_width = rs.currentTarget['width'];
          console.log(img_height, img_width);
          if (img_height == max_height && (img_width == max_width || img_width == max_width2)) {
            this.imgBase64Path = e.target.result;
          } else {
            this.imgBase64Path = ''
            this.addBannerForm.controls['bannerFile'].reset()
            this.mainService.errorToast('Please upload image of size 1920 * 450 or 1640 * 450 only.')
          }
        };
      };
      reader.readAsDataURL(fileInput.target.files[0]);
    }
  }

  toggleVisibility(e) {
    this.marked = e.target.checked;
  }

}
