import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { MainService } from 'src/app/provider/main.service';

@Component({
  selector: 'app-sub-admin-management',
  templateUrl: './sub-admin-management.component.html',
  styleUrls: ['./sub-admin-management.component.css']
})
export class SubAdminManagementComponent implements OnInit {

  
  currentPage: any = 1;
  itemPerPage: any = 10;
  total: any;
  subAdminForm : FormGroup
  userId : any
  flip : boolean = false
  resetPage : boolean= false
userStatus : any 
isSearched : boolean = false
  subAdminList : any = [
   
  ]

  constructor(private router: Router, private mainService: MainService, private datepipe : DatePipe) { }


  ngOnInit() {
    this.getSubAdminList()
     this.subAdminForm = new FormGroup({
       search : new FormControl(''),
 
     })
  }

  getSubAdminList(){
    const data = {
      page : this.currentPage,
      limit : this.itemPerPage
    }
   let url = "admin/subAdminList"
   this.mainService.showSpinner()
   this.mainService.postApi(url,data,1).subscribe((res)=>{
     if(res.responseCode == 200){
       this.subAdminList = res.result.docs
       
       this.total = res.result.total
      this.mainService.hideSpinner()
     }else{
      this.mainService.hideSpinner()
     }
   },(err)=>{
    this.mainService.hideSpinner()
   })
  }
  /* search */
  search(){
    if(!this.subAdminForm.value.search){
      return
    }
    this.isSearched = true
    this.flip =  true
    if(!this.resetPage){
      this.currentPage = 1
    }
    const data = {
      page : this.currentPage,
      limit : this.itemPerPage,
      search : this.subAdminForm.value.search
    }
   let url = "admin/subAdminList"
   this.mainService.showSpinner()
   this.mainService.postApi(url,data,1).subscribe((res)=>{
     if(res.responseCode == 200){
       this.subAdminList = res.result.docs
       this.total = res.result.total
      this.mainService.hideSpinner()
     }else{
      this.mainService.hideSpinner()
     }
   },(err)=>{
    this.mainService.hideSpinner()
   })
    this.resetPage = true
  }

  pagination(event) {
    this.currentPage = event;
    
if(this.flip){
this.search()
}else{
  this.getSubAdminList()
}
}

  viewSubAdmin(id){
   
    this.router.navigate(["/view-sub-admin"],{queryParams:{id:id }})
  }
  editSubAdmin(id){
   
    this.router.navigate(["/edit-sub-admin"],{queryParams:{id:id}})
  }

  blockModal(id,stat){
    this.userId = id
    this.userStatus = stat
    console.log(this.userId);
    

  }
  blockSubAdmin(){
    let url = "admin/blockUnblockSubAdmin"
    const data = {
      "userId" : this.userId
  }
  this.mainService.showSpinner()
  this.mainService.postApi(url,data,1).subscribe((res)=>{
    if(res.responseCode == 200){
      this.mainService.hideSpinner()
      this.mainService.successToast(res.responseMessage)
      this.getSubAdminList()
    }
    else{
      this.mainService.hideSpinner()
      this.mainService.errorToast(res.responseMessage)
    }
  },(err)=>{
    this.mainService.hideSpinner()
      this.mainService.errorToast(err)
  })
  }
  reset(){
    if(!this.isSearched){
      return
    }
    this.isSearched = false
this.flip = false
this.resetPage = false
   this.subAdminForm.reset()
    this.getSubAdminList()
  }
  deleteModal(id){
    this.userId = id
  }
  deleteAdmin(){
   
  }
  exportAsXLSX() {
  
    let dataArr = [];
    this.subAdminList.forEach((element, ind) => {
      let obj = {}
      obj = {
        "S no": ind + 1,
        "Name" : element.firstName ? element.firstName : 'N/A',
        "Email": element.email ? element.email : 'N/A',

        "Phone No": element.mobileNumber ? element.mobileNumber : 'N/A',
        "Created At": element.updatedAt ?this.datepipe.transform(element.updatedAt ,'dd/MM/yyyy, hh:mm a')  : 'N/A',
        "Status": element.status ? element.status  : 'N/A',

      }
      dataArr.push(obj)
    })

    this.mainService.exportAsExcelFile(dataArr, 'Sub Admin Management');
  }
  currLat : any
  currLng : any
  ipAddress : any
  deleteSubAdmin(){
    let url = "admin/deleteSubAdmin"
    this.getCurrentLocation()
    const body = {
      "subAdminId": this.userId,
    
      
    }
    this.mainService.showSpinner()
    this.mainService.postApi(url,body,1).subscribe((res)=>{
      if (res.responseCode == 200) {
        this.mainService.hideSpinner()
        this.mainService.successToast(res.responseMessage)
       
        if(this.subAdminList.length == 1){
          this.currentPage = this.currentPage - 1
          
        }
        this.getSubAdminList()
      } else {
        this.mainService.hideSpinner()
        this.mainService.errorToast(res.responseMessage)
      }
    },(err)=>{
      this.mainService.hideSpinner()
      this.mainService.errorToast(err)
    })
  }
  getCurrentLocation() {
  }
}
