import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MainService } from 'src/app/provider/main.service';

@Component({
  selector: 'app-view-service',
  templateUrl: './view-service.component.html',
  styleUrls: ['./view-service.component.css']
})
export class ViewServiceComponent implements OnInit {
  profile = ''
  _id: any;
  serviceDetails: any = []
  ServiceValue: boolean;
 
  Isapprove: boolean;
  constructor(private router: Router, public mainService: MainService, private activateRoute: ActivatedRoute) { }


  ngOnInit() {
    this.activateRoute.params.subscribe((url: any) => {
      this._id = url.id;
    })
    this.SetBannerItemInEditForm()
  }
  SetBannerItemInEditForm() {
    this.mainService.showSpinner();
    this.mainService.getApi(`admin/viewTransaction/${this._id}`, 1).subscribe((res: any) => {
      console.log('View Banner Details', res.result)
      this.mainService.hideSpinner();
      if (res.responseCode == 200) {
        this.serviceDetails = res.result;
        this.profile = this.serviceDetails.image
      } else {
        this.mainService.errorToast(res.responseMessage)
      }
    },
      error => {
        this.mainService.hideSpinner()
      })

  }
  navigateTo(){
    console.log('------>95')
    this.router.navigate(['/transaction-management'],{queryParams:{navFrom:'BUY'}})
  }

}