import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MainService } from 'src/app/provider/main.service';
import { ApiUrls } from 'src/app/config/api-urls/api-urls';

@Component({
  selector: 'app-view-user',
  templateUrl: './view-user.component.html',
  styleUrls: ['./view-user.component.css']
})
export class ViewUserComponent implements OnInit {
  userId: any;
  currTab: any;
  viewCustomer: any;
  customerMedicalHistrySectionShow: any;
  customerMedicalHistoryArray = [];
  currentPage: number = 1
  itemPerPage: number = 5
  customerMedicalHistoryArrayTotal: any;
  viewCustomerMedicalHistoryData: any;

  constructor(private activatedroute: ActivatedRoute, private router: Router, public mainService: MainService) { }

  ngOnInit() {
    this.activatedroute.queryParams.subscribe((res) => {
      this.userId = res.userId;
    })
    this.selectTab('GENERAL');
  }

  selectTab(tab) {
    this.currTab = tab;
    if (this.currTab == 'GENERAL') {
      this.viewCustomerData()
    } else {
      this.getCustomerMedicalHistory()
    }
  }

  // view customer api
  viewCustomerData() {
    let data = {
      'customerId': this.userId
    }
    this.mainService.showSpinner();
    this.mainService.postApi('admin/viewCustomer', data, 1).subscribe((res) => {
      console.log('View Custome', res)
      if (res.responseCode == 200) {
        this.mainService.hideSpinner();
        this.viewCustomer = res.result[0]
      } else {
        this.mainService.hideSpinner();
        this.mainService.errorToast(res.responseMessage)
      }
    }, (error) => {
      this.mainService.hideSpinner();
    })
  }

  back() {
    this.router.navigate(['/user-management'])
  }



  /**
   * Medical History Section
   * 
   */

  // ---------------- get medical history list of user ----------------- //
  getCustomerMedicalHistory() {
    this.customerMedicalHistrySectionShow = 'TABLE';
    let data = {
      'page': this.currentPage,
      'limit': this.itemPerPage
    }
    this.mainService.showSpinner();
    this.mainService.postApi(`admin/viewMedicalReportList/${this.userId}`, data, 1).subscribe((res) => {
      console.log('View Custome', res)
      if (res.responseCode == 200) {
        this.mainService.hideSpinner();
        this.customerMedicalHistoryArray = res.result.docs;
        this.customerMedicalHistoryArrayTotal = res.result.total
      } else {
        this.mainService.hideSpinner();
      }
    }, (error) => {
      this.mainService.hideSpinner();
    })
  }

  pagination(event) {
    this.currentPage = event;
    this.getCustomerMedicalHistory();
  }

  viewCustomerMedicalHistory(objClicked) {
    this.customerMedicalHistrySectionShow = objClicked.medicalType;
    console.log(this.customerMedicalHistrySectionShow)
    this.viewCustomerMedicalHistoryData = objClicked
    console.log(this.viewCustomerMedicalHistoryData)
  }

  // -------------------- on click back button show medical report table ------------------------- //
  changeValue() {
    this.customerMedicalHistrySectionShow = 'TABLE';
  }

}
