import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-edit-corporate-customer',
  templateUrl: './edit-corporate-customer.component.html',
  styleUrls: ['./edit-corporate-customer.component.css']
})
export class EditCorporateCustomerComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
