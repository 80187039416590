import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { MainService } from 'src/app/provider/main.service';

@Component({
  selector: 'app-home-banner-edit',
  templateUrl: './home-banner-edit.component.html',
  styleUrls: ['./home-banner-edit.component.css']
})
export class HomeBannerEditComponent implements OnInit {

  EditBannerForm: FormGroup
  profile = ''
  BannerEditId: any;
  bannerFormValues: any = []
  marked = false;
  theCheckbox = false;
  config = {
    uiColor: '#F0F3F4',
    height: '40%',
    enterMode: 2

  };
  imgBase64Path: string;
  editorShow: boolean = false;

  constructor(private router: Router, public mainService: MainService, private activateRoute: ActivatedRoute) { }

  ngOnInit() {
    this.activateRoute.params.subscribe((url: any) => {
      this.BannerEditId = url.id;
      console.log('This is editId', url.id)
    })
    this.SetBannerItemInEditForm()

    this.EditBannerForm = new FormGroup({
      'title': new FormControl('', [Validators.required, Validators.pattern(/^[a-zA-Z ]*$/i)]),

      "description": new FormControl('', Validators.required),
      "bannerFile": new FormControl('', Validators.required)
    });
  }

  // ------------------- get banner detials ---------------------- //
  SetBannerItemInEditForm() {
    this.mainService.showSpinner();
    this.mainService.getApi(`admin/viewHomeScreen/${this.BannerEditId}`, 1).subscribe((res: any) => {
      console.log('View Banner Details', res.result)
      this.mainService.hideSpinner();
      this.editorShow = true;
      if (res.responseCode == 200) {
        this.bannerFormValues = res.result;
        this.theCheckbox = res.result.active
        this.imgBase64Path = this.bannerFormValues.image
          this.EditBannerForm.patchValue({
            'title': this.bannerFormValues.title,
            'description': res.result.description
          });
      } else {
        this.mainService.errorToast(res.responseMessage)
      }
    }, error => {
      this.editorShow = true;
      this.mainService.hideSpinner()
    })
  }

  // -------------------- edit banner -------------------- //
  EditBanner() {
    if (!this.imgBase64Path) {
      return this.mainService.errorToast('Please upload image.')
    }
    let url = 'admin/editHomeScreen'
    let data = {
      '_id': this.BannerEditId,
      'active': this.marked,
      'description': this.EditBannerForm.value.description,
      'title': this.EditBannerForm.value.title,
      'image': this.imgBase64Path
    }
    this.mainService.showSpinner();
    this.mainService.putApi(url, data, 1).subscribe((res: any) => {
      console.log("EditBanner  response ==>", res)
      this.mainService.hideSpinner();
      if (res.responseCode == 200 && res.result) {
        this.mainService.successToast(res.responseMessage)
        this.router.navigate(['/home-banner'])
        this.EditBannerForm.reset()
      } else {
        this.mainService.hideSpinner();
        this.mainService.errorToast(res.responseMessage)
      } error => {
        this.mainService.hideSpinner();
      }
    })
  }

  // upload image 
  fileChangeEvent(fileInput: any) {
    if (fileInput.target.files && fileInput.target.files[0]) {
      const max_height = 450;
      const max_width = 1920;
      const max_width2 = 1640;
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const image = new Image();
        image.src = e.target.result;
        image.onload = rs => {
          const img_height = rs.currentTarget['height'];
          const img_width = rs.currentTarget['width'];
          console.log(img_height, img_width);
          if (img_height == max_height && (img_width == max_width || img_width == max_width2)) {
            this.imgBase64Path = e.target.result;
          } else {
            this.imgBase64Path = ''
            this.EditBannerForm.controls['bannerFile'].reset()
            this.mainService.errorToast('Please upload image of size 1920 * 450 or 1640 * 450 only.')
          }
        };
      };
      reader.readAsDataURL(fileInput.target.files[0]);
    }
  }


  toggleVisibility(e) {
    this.marked = e.target.checked;
  }
}
