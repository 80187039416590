import { Component, OnInit } from '@angular/core';
import { ngxCsv } from 'ngx-csv/ngx-csv';
import { FormGroup, FormControl } from '@angular/forms';
import { MainService } from 'src/app/provider/main.service';
import { ActivatedRoute } from '@angular/router';
declare var $: any;

@Component({
  selector: 'app-transaction-management',
  templateUrl: './transaction-management.component.html',
  styleUrls: ['./transaction-management.component.css']
})
export class TransactionManagementComponent implements OnInit {
  searchForm: FormGroup;
  serviceForm:FormGroup;
  page: any = 1;
  transactionLength:any = 0
  serviceLength:any =0
  transactionList: any = [];
  limit: any = 5;
  total:any=0;
 
  _Id: any;
  status: string;
  flip: string = 'LIST';
  typeval: string;
  currTab: any = 'Product';
  Isapprove: boolean;
  navigate:any
  customerUserValue: boolean;
  customerUserEditValue: boolean;
  customerUserAddValue: boolean;
  corporateUserValue: boolean;
  corporateUserEditValue: boolean;
  corporateUserAddValue: boolean;
  corporateValue: boolean;
  viewCompanyValue: boolean;
  ProductValue: boolean;
  ServiceValue: boolean;
  serviceTransaction: any = [];
  transactionArray: any = [];
  serviceTotal: any;
  searchValues: any;
loginType:any = localStorage.getItem('userType')
  transactionListService: any=[];
  CategorySearch: any;
  ServiceSearch: string;
  brr:any=[];
  crr:any=[]
  constructor(public mainService: MainService, private activatedRoute: ActivatedRoute,) { }

  ngOnInit() {
    this.exportMethod()
    this.productMethod()
this.find()
    this.activatedRoute.queryParams.subscribe(res => {
      if (res.navFrom == 'BUY') {
        this.selectTab('Service')
        this.currTab = 'Service'
        this.getTransactionList();
        this.Isapprove = false;
        this.ServiceValue = true;
        this.ProductValue = false;
      }
      else {

        if (this.currTab === 'Product') {
          this.selectTab('Product')
        }
        (this.currTab = 'Product')
        this.getTransactionList();
        this.Isapprove = false;
        this.ProductValue = true; 
      }
    })
  }
find(){
  this.searchForm = new FormGroup({
    search: new FormControl(''),
    fromDate: new FormControl(''),
    toDate: new FormControl(''),
    searchType:new FormControl('')
  });
}

findSearch(){
  this.serviceForm = new FormGroup({
    search: new FormControl(''),
    fromDate: new FormControl(''),
    toDate: new FormControl(''),
    searchType:new FormControl('')
  });
}
  getTransactionList() {
    this.mainService.showSpinner()
    let formdata = {
      'page': this.page,
      'limit': this.limit
    }
    this.mainService.postApi('admin/transactionList', formdata, 1).subscribe((res: any) => {
      if (res['responseCode'] == 200) {
        this.mainService.hideSpinner()
        this.transactionList = res.result.docs
        console.log(res['result']['docs'])
        this.total = res.result.total
        console.log(res)
      } else {
        this.mainService.hideSpinner()
        this.transactionList = 0
        this.mainService.errorToast(res.responseMessage)
      }
    }, (err) => {
      this.mainService.hideSpinner()
      this.transactionList = 0
      this.mainService.errorToast(err.responseMessage)
    })
  }
  
   getServiceList() {
     this.mainService.showSpinner()
     let formdata = {
       'page': this.page,
       'limit': this.limit
     }
     this.mainService.postApi('admin/transactionListService', formdata, 1).subscribe((res: any) => {
       if (res['responseCode'] == 200) {
         this.mainService.hideSpinner()
         this.transactionListService = res.result.docs
         console.log(res['result']['docs'])
         this.total = res.result.total
         console.log(res)
       } else {
         this.mainService.hideSpinner()
         this.transactionListService = 0
         this.mainService.errorToast(res.responseMessage)
       }
     }, (err) => {
       this.mainService.hideSpinner()
       this.transactionListService = 0
       this.mainService.errorToast(err.responseMessage)
     })
   } 

  resetProduct(){
    this.flip = 'LIST'
    this.searchForm.reset()

  this.searchForm.get('searchType').setValue('select')
      this.getTransactionList()
  }
  resetService(){
    this.flip = 'LIST'
   this.serviceForm.reset()
   this.serviceForm.get('searchType').setValue('select')
    this.getServiceList()
 
}
  searchValue() {
 

    this.flip = 'TYPE'
   
    this.mainService.showSpinner()
    let formdata = {
      'userType': this.searchForm.controls.searchType.value,
      'fromDate': Date.parse(this.searchForm.controls.fromDate.value),
      'toDate': Date.parse(this.searchForm.controls.toDate.value),
      'page': this.page,
      'limit': this.limit
    }
    this.mainService.postApi('admin/transactionList', formdata, 1).subscribe((res: any) => {
      if (res['responseCode'] == 200) {
        this.mainService.hideSpinner()
        this.transactionList = res.result.docs
        this.total = res.result.total
        console.log(res)
      } else {
        this.mainService.hideSpinner()
        this.transactionList = []
        this.total = 0
        this.mainService.errorToast(res.responseMessage)
      }
    }, (err) => {
      this.mainService.hideSpinner()
      this.transactionList = []
      this.total = 0
    })
  }

  search() {
    this.flip = 'DATE'
    this.mainService.showSpinner()
    let formdata = {
       'search': this.searchForm.controls.search.value,
      'fromDate': Date.parse(this.searchForm.controls.fromDate.value),
      'toDate': Date.parse(this.searchForm.controls.toDate.value),
      'page': this.page,
      'limit': this.limit
    }
    this.mainService.postApi('admin/transactionList', formdata, 1).subscribe((res: any) => {
      if (res['responseCode'] == 200) {
        this.mainService.hideSpinner()
        this.transactionList = res.result.docs
        this.total = res.result.total
        console.log(res)
      } else {
        this.mainService.hideSpinner()
        this.transactionList = []
        this.mainService.errorToast(res.responseMessage)
      }
    }, (err) => {
      this.mainService.hideSpinner()
      this.transactionList = []
    })
  }
  searchService() {
    this.flip = 'DATE'
    this.mainService.showSpinner()
    let formdata = {
      'fromDate': Date.parse(this.serviceForm.controls.fromDate.value),
      'toDate': Date.parse(this.serviceForm.controls.toDate.value),
      'page': this.page,
      'limit': this.limit
    }
    this.mainService.postApi('admin/transactionListService', formdata, 1).subscribe((res: any) => {
      if (res['responseCode'] == 200) {
        this.mainService.hideSpinner()
        this.transactionListService = res.result.docs
        this.total = res.result.total
        console.log(res)
      } else {
        this.mainService.hideSpinner()
        this.transactionListService = []
        this.mainService.errorToast(res.responseMessage)
      }
    }, (err) => {
      this.mainService.hideSpinner()
      this.transactionListService = []
    })
  }
  searchServiceValue() {
 
   this.flip = 'TYPE'
   console.log(this.flip)

   this.mainService.showSpinner()
   let formdata = {
     'userType': this.serviceForm.controls.searchType.value,
     'fromDate': Date.parse(this.serviceForm.controls.fromDate.value),
     'toDate': Date.parse(this.serviceForm.controls.toDate.value),
     'page': this.page,
     'limit': this.limit
    }
    this.mainService.postApi('admin/transactionListService', formdata, 1).subscribe((res: any) => {
      if (res['responseCode'] == 200) {
        this.mainService.hideSpinner()
        this.transactionListService = res.result.docs
        this.total = res.result.total
        console.log(res)
      } else {
        this.mainService.hideSpinner()
        this.transactionListService = []
        this.mainService.errorToast(res.responseMessage)
      }
    }, (err) => {
      this.mainService.hideSpinner()
      this.transactionListService = []
    })
  }
  deleteFunction(id) {
    this._Id = id
    $('#deleteModal').modal({ backdrop: 'static', keyboard: false })
  }

  deleteUser() {
    this.mainService.showSpinner()
    let object = {
      '_id': this._Id
    }
    this.mainService.postApi('admin/deleteTransaction', object, 1).subscribe(res => {
      console.log('delete id=========>', res)
      if (res.responseCode == 200) {
        this.getTransactionList()
        this.mainService.hideSpinner()
        $('#deleteModal').modal('hide');
        this.mainService.successToast(res.responseMessage)

      } else {
        $('#deleteModal').modal('hide');
        this.mainService.hideSpinner()
        this.mainService.errorToast(res.responseMessage)
      }
    }, error => {
      this.mainService.hideSpinner()
      this.mainService.errorToast(error.responseMessage)
    })
  }


  pagination(event) {
 
    this.page = event;
    if (this.flip == 'TYPE') {
      this.searchValue();
      
    } 
    else if (this.flip == 'DATE') {
      this.search()
    } else {
      this.getTransactionList()
    } 
  }

  paginationService(event) {
 
    this.page = event;
     if (this.flip == 'TYPE') {
      this.searchServiceValue();
      
    }
     else if (this.flip == 'DATE') {
      this.searchService()
    } else {
      this.getServiceList()
    } 
  }

//service export csv

exportCSV(){
  let dataArr = [];



  this.brr.forEach((element, ind) => {

    dataArr.push({
      'S.No.': ind + 1,
      'TransactionID': element.payementData.reference ? element.payementData.reference : '--',
      'SoldBy': element.productUserType ? element.productUserType : '--',
      'CreatedAt': element.createdAt ? element.createdAt : '--',
      'Status': element.status ? element.status : '--',
  })
})

  this.mainService.exportAsExcelFile(dataArr, 'service user')
}
//product
exportproductCSV() {
console.log('exportproductCSV',this.crr)
 let dataArr = [];



  this.crr.forEach((element, ind) => {


     dataArr.push({
      'TransactionID': element.payementData.reference ? element.payementData.reference : '--',
      'SoldBy': element.productUserType ? element.productUserType : '--',
      'CreatedAt': element.createdAt ? element.createdAt.split("T")[0] : '--',
       'Quantity': element.noOfProduct ? element.noOfProduct : '--',
     'Price': element.price ? element.price : '--', 
      'Status': element.transactionStatus ? element.transactionStatus : '--',
  }) 
})
console.log(dataArr)
  this.mainService.exportAsExcelFile(dataArr, 'poduct user')
}


  //service
    exportMethod(){
      let data = {
        'page': this.page,
        'limit':  500,
      }
      this.mainService.postApi('admin/transactionListService', data, 1).subscribe((res: any) => {
        this. brr = res.result.docs;
      },
      )}

      //product
      productMethod(){

    let data = {
    'page': this.page,
    'limit':  500,
  }
this.mainService.showSpinner()
  this.mainService.postApi('admin/transactionList', data, 1).subscribe((res: any) => {
   
    this. crr = res.result.docs;
    console.log(this.crr )
    this.mainService.hideSpinner()
  },
  )}
  selectTab(tab) {
    this.find();
    this.findSearch();
    this.currTab = tab;
    if (this.currTab == 'Product') {
      this.getTransactionList();
      this.Isapprove = false;
      this.ProductValue = true;
    }
    else if (this.currTab == 'Service') {
      this.serviceForm.get('searchType').setValue('select')
      this.getServiceList();
      this.Isapprove = false;
      this.ServiceValue = true;
      this.ProductValue = false;
    }
  }
}

