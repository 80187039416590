import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MainService } from 'src/app/provider/main.service';
declare var $
@Component({
  selector: 'app-tax-management',
  templateUrl: './tax-management.component.html',
  styleUrls: ['./tax-management.component.css']
})
export class TaxManagementComponent implements OnInit {
  editSubForm: FormGroup;
  currentPage: any;
  itemPerPage: any;
  userDataList: any = [];
  total = 0;
  numRegxForDot = (/^\d{0,6}(\.\d{1,6})?$/);
  gifiId: any;
  tax: any;
  userId = ''
  subCategoryView: any;
  user: any;
  id: any;
  editid: any;
  constructor(public service: MainService, private activatedroute: ActivatedRoute, private router: Router) { }
 
  ngOnInit() {
 

    this.activatedroute.params.subscribe((res: any) => {
      this.gifiId = res.id
    })
    this.getSectionList()


    this.editSubForm = new FormGroup({
      "tax": new FormControl('', [Validators.required, Validators.pattern((/^\d{0,6}(\.\d{1,6})?$/))]),
    });

  }
  getSectionList() {
    let url = `admin/taxList`
    this.service.showSpinner()
    this.service.getApi(url, 1).subscribe((res: any) => {
      this.service.hideSpinner()
      console.log("This is Section ListLen", res.result.length)
      console.log('Res of HomeBanner', res)
      if (res.responseCode == 200) {
        this.userDataList = res.result
        this.total = res.result.length
        this.service.successToast(res.responseMessage)
      }
      else {
        this.service.errorToast(res.responseMessage);
      }
    }, err => {
      this.service.hideSpinner()
      this.service.errorToast('Somthing went wrong')
    }
    )
  }


  pagination(page) {
    this.currentPage = page;
    this.getSectionList()
  }
  //patch value 
  editFunction(_id) {
    this.editid = _id
    $('#editSub').modal('show')
    this.service.showSpinner();
    this.service.getApi('admin/viewTax/' + this.editid, 1).subscribe((res: any) => {
      if (res.responseCode == 200 && res.result) {
        this.service.hideSpinner();
        this.service.successToast(res.responseMessage)

        this.editSubForm.patchValue({
          "tax": res.result.taxAmount
        })
      } else {
        this.service.hideSpinner();
        this.service.errorToast(res.responseMessage)
      }
      error => {
        this.service.hideSpinner();
        this.service.errorToast(error.responseMessage)
      }
    })
  }
  //update value
  editSubService() {
    let data =
    {
      "taxAmount": this.editSubForm.value.tax
    }
    var url = 'admin/editTax/' + this.editid;
    this.service.showSpinner();
    this.service.putApi(url, data, 1).subscribe((res: any) => {
      console.log("addProduct response ==>", res)

      if (res.responseCode == 200 && res.result) {



        this.service.hideSpinner();

        this.service.successToast(res.responseMessage)
        $('#editSub').modal('hide');
        this.getSectionList()
      } else {
        this.service.hideSpinner();
        this.service.errorToast(res.responseMessage)
        $('#editSub').modal('hide');

      }
      error => {
        this.service.hideSpinner();
        this.service.errorToast(error.responseMessage)
      }

    })

  }

  // ---------------------get category details ----------------------- //
  viewCategory(_id) {

    this.service.showSpinner();
    $('#viewSub').modal('show')
    this.service.getApi('admin/viewTax/' + _id, 1).subscribe((res: any) => {
      if (res && res.responseCode == 200 && res.result) {
        this.tax = res.result.taxAmount


        this.service.hideSpinner();
        this.service.successToast(res.responseMessage)
      } else {
        this.service.hideSpinner();
        this.service.errorToast(res.responseMessage)
      }
      error => {
        this.service.hideSpinner();
        this.service.errorToast(error.responseMessage)
      }
    })
  }
}
