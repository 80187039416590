import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-view-corporate-customer',
  templateUrl: './view-corporate-customer.component.html',
  styleUrls: ['./view-corporate-customer.component.css']
})
export class ViewCorporateCustomerComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
