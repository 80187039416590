import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-add-corporate-customer',
  templateUrl: './add-corporate-customer.component.html',
  styleUrls: ['./add-corporate-customer.component.css']
})
export class AddCorporateCustomerComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
