import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { MainService } from 'src/app/provider/main.service';

@Component({
  selector: 'app-edit-login-content',
  templateUrl: './edit-login-content.component.html',
  styleUrls: ['./edit-login-content.component.css']
})
export class EditLoginContentComponent implements OnInit {

  EditBannerForm: FormGroup
  SideImage = ''
  BannerEditId: any;
  bannerFormValues: any = []

  config = {
    uiColor: '#F0F3F4',
    height: '40%',
    enterMode: 2,
    fillEmptyBlocks: false
  };

  imgBase64Path: string;
  editorShow: boolean = false;

  constructor(private router: Router, public mainService: MainService, private activateRoute: ActivatedRoute) { }

  ngOnInit() {
    this.activateRoute.params.subscribe((url: any) => {
      this.BannerEditId = url.id;
      console.log('This is editId', url.id)
    })
    this.SetBannerItemInEditForm()

    this.EditBannerForm = new FormGroup({
      'title': new FormControl('', [Validators.required, Validators.pattern(/^[a-zA-Z ]*$/i)]),

      "description": new FormControl('', Validators.required),
      "bannerFile": new FormControl('', Validators.required)
    });
  }

  // ------------------- edit form submit -------------------- //
  EditBanner() {
    if (!this.imgBase64Path) {
      return this.mainService.errorToast('Please upload image.')
    }
    let url = 'admin/editPractitionerLoginScreen'
    let data = {
      '_id': this.BannerEditId,
      'description': this.EditBannerForm.value.description,
      'title': this.EditBannerForm.value.title,
      'bannerImage': this.imgBase64Path,
      'sideImage': this.SideImage
    }
    this.mainService.showSpinner();
    this.mainService.putApi(url, data, 1).subscribe((res: any) => {
      console.log("Update Practioner  response ==>", res)
      this.mainService.hideSpinner();
      if (res.responseCode == 200 && res.result) {
        this.mainService.successToast(res.responseMessage)
        this.router.navigate(['/login-screen-content'])
        this.EditBannerForm.reset()
      } else {
        this.mainService.hideSpinner();
        this.mainService.errorToast(res.responseMessage)
      }
      error => {
        this.mainService.hideSpinner();
      }
    })
  }

  fileChangeEvent(fileInput: any) {
    if (fileInput.target.files && fileInput.target.files[0]) {
      const max_height = 450;
      const max_width = 1920;
      const max_width2 = 1640;
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const image = new Image();
        image.src = e.target.result;
        image.onload = rs => {
          const img_height = rs.currentTarget['height'];
          const img_width = rs.currentTarget['width'];
          console.log(img_height, img_width);
          if (img_height == max_height && (img_width == max_width || img_width == max_width2)) {
            this.imgBase64Path = e.target.result;
          } else {
            this.imgBase64Path = ''
            this.EditBannerForm.controls['bannerFile'].reset()
            this.mainService.errorToast('Please upload image of size 1920 * 450 or 1640 * 450 only.')
          }
        };
      };
      reader.readAsDataURL(fileInput.target.files[0]);
    }
  }

  SetBannerItemInEditForm() {
    this.mainService.showSpinner();
    this.mainService.getApi(`admin/viewPractitionerLoginScreen/${this.BannerEditId}`, 1).subscribe((res: any) => {
      console.log('View Banner Details', res.result)
      this.mainService.hideSpinner();
      this.editorShow = true;
      if (res.responseCode == 200) {
        this.bannerFormValues = res.result;
        this.imgBase64Path = this.bannerFormValues.bannerImage
        this.SideImage = this.bannerFormValues.sideImage
        this.EditBannerForm.patchValue({
          'title': this.bannerFormValues.title,
          'description': this.bannerFormValues.description
        });
      } else {
        this.mainService.errorToast(res.responseMessage)
      }
    },error => {
        this.editorShow = true;
        this.mainService.hideSpinner()
      })
  }

  
  SideImageInput(e) {

    var file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];

    var reader = new FileReader();
    reader.onload = this._SideImageLoaded.bind(this);
    reader.readAsDataURL(file);
  }
  _SideImageLoaded(e) {
    let reader = e.target;
    this.SideImage = reader.result;
  }
}
