import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MainService } from 'src/app/provider/main.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-edit-category',
  templateUrl: './edit-category.component.html',
  styleUrls: ['./edit-category.component.css']
})
export class EditCategoryComponent implements OnInit {
  profile: any;
  editproductForm: FormGroup;
  fileToupload: File = null;
  user: any;
  categoryId: any;
  constructor(private actRoute: ActivatedRoute, private route: Router, public mainService: MainService) { 
    this.editproductForm = new FormGroup({
      categoryName: new FormControl('', [Validators.required]),
    })
  }

  ngOnInit() {
 
    this.actRoute.paramMap.subscribe(params => {
      this.categoryId = params.get('id');
       this.vewCategory();
    });
   
  }

  vewCategory(){
    this.mainService.showSpinner();
    this.mainService.getApi('admin/viewProductCagtegory?categoryId=' + this.categoryId, 1).subscribe((res: any) => {
      console.log("editProduct response ==>", res)
      if (res.responseCode == 200 && res.result) {
        this.user = res.result;
        this.profile = this.user.categoryImage;
        this.editproductForm.patchValue({
          'categoryName': this.user.categoryName,
        })
        this.mainService.hideSpinner();
        this.mainService.successToast(res.responseMessage)

      } else {
        this.mainService.hideSpinner();
        this.mainService.errorToast(res.responseMessage)
      }
      error => {
        this.mainService.hideSpinner();
        this.mainService.errorToast(error.responseMessage)
      }
    })

  }

  
  handleInputChange(e) {
    var file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];

    var reader = new FileReader();
    reader.onload = this._handleReaderLoaded.bind(this);
    reader.readAsDataURL(file);
  }
  _handleReaderLoaded(e) {
    let reader = e.target;
    this.profile = reader.result;
    console.log("profile", this.profile)
  }

  editProduct() {
    this.mainService.showSpinner();
    let data =
    {
      'categoryId': this.categoryId,
      'categoryName': this.editproductForm.value.categoryName,
      'categoryImage': this.profile
    }

    this.mainService.putApi('admin/editProductCategory', data, 1).subscribe((res: any) => {
      if (res.responseCode == 200) {
        this.mainService.hideSpinner();
        this.mainService.successToast(res.responseMessage)
        this.route.navigate(['/product-management'],{queryParams:{navFrom:'CATEGORY'}})

      } else {
        this.mainService.hideSpinner();
        this.mainService.errorToast(res.responseMessage)
      }
      error => {
        this.mainService.hideSpinner();
        this.mainService.errorToast(error.responseMessage)
      }
    })
  }

  navigateTo(){
    this.route.navigate(['/product-management'],{queryParams:{navFrom:'CATEGORY'}})
  }

}
