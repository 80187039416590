import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MainService } from 'src/app/provider/main.service';

@Component({
  selector: 'app-view-transaction',
  templateUrl: './view-transaction.component.html',
  styleUrls: ['./view-transaction.component.css']
})
export class ViewTransactionComponent implements OnInit {

  profile = ''
  _id: any;
  transactionDetails: any = []
  constructor(private router: Router, public mainService: MainService, private activateRoute: ActivatedRoute) { }

  ngOnInit() {
    this.activateRoute.params.subscribe((url: any) => {
      this._id = url.id;
    })
    this.SetBannerItemInEditForm()
  }
  SetBannerItemInEditForm() {
    this.mainService.showSpinner();
    this.mainService.getApi(`admin/viewTransaction/${this._id}`, 1).subscribe((res: any) => {
      console.log('View Banner Details', res.result)
      this.mainService.hideSpinner();
      if (res.responseCode == 200) {
        this.transactionDetails = res.result;
        this.profile = this.transactionDetails.image
      } else {
        this.mainService.errorToast(res.responseMessage)
      }
    },
      error => {
        this.mainService.hideSpinner()
      })

  }

}

