import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MainService } from 'src/app/provider/main.service';

@Component({
  selector: 'app-add-sub-admin',
  templateUrl: './add-sub-admin.component.html',
  styleUrls: ['./add-sub-admin.component.css']
})
export class AddSubAdminComponent implements OnInit {

  editform: FormGroup;
  array: any = [];

  permissionArr: any = [
    "dashboardManagement",
    "vendorManagement",
    "subAdminManagement",
    "productManagement",
    "serviceManagement",
    "giftCardManagement",
    "transactionManagement",
    "notificationManagement",
    "staticContentManagement",
    "referralManagement"
  ];
  privilage: any = {
    "dashboardManagement" : false,
    "vendorManagement" : false,
    "subAdminManagement" : false,
    "productManagement" : false,
    "serviceManagement" : false,
    "giftCardManagement": false,
    "transactionManagement" : false,
    "notificationManagement" : false,
    "staticContentManagement" : false,
    "referralManagement" : false,

  };

  name: any = [];
  check: any = [];

  index = 0;
  constructor(public service: MainService, private route: Router) {}

  ngOnInit() {
    for (let i of this.permissionArr) {
      this.name[this.index] = String(i).split(/(?=[A-Z])/).join(" ");
      this.index++;
    }
    this.editform = new FormGroup({
      name: new FormControl("", [
        Validators.required,
        Validators.pattern(/^[a-zA-Z ]*$/i),
      ]),
      lastName: new FormControl("", [
        Validators.required,
        Validators.pattern(/^[a-zA-Z ]*$/i),
      ]),
      email: new FormControl("", [Validators.required, Validators.email]),
      phone: new FormControl("", [
        Validators.required,
        Validators.pattern(/^[^0][0-9]*$/),
      ]),
      password: new FormControl("", [
        Validators.required,
        Validators.pattern(
          /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,16}$/
        ),
      ]),
    });
  }
  addSubAdmin() {
    let url = "admin/addSubAdmin";
    
    
    this.privilage["firstName"] = this.editform.value.name
    this.privilage["lastName"] = this.editform.value.lastName
    this.privilage["email"] = this.editform.value.email
    this.privilage["mobileNumber"] = this.editform.value.phone
    this.privilage["password"] = this.editform.value.password

  
    
    
    this.service.showSpinner();
    this.service.postApi(url, this.privilage, 1).subscribe(
      (res) => {
        if (res.responseCode == 200) {
          this.service.hideSpinner();
          this.service.successToast(res.responseMessage);
          this.route.navigate(["/sub-admin-management"]);
        } else {
          this.service.hideSpinner();
          this.service.errorToast(res.responseMessage);
        }
      },
      (err) => {
        this.service.hideSpinner();
        this.service.errorToast(err);
      }
    );
  }

  checkPerm(permName, ch) {
    console.log(permName, ch);
    this.privilage[permName] = ch
    console.log(this.privilage);
  }

}
