import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MainService } from 'src/app/provider/main.service';

@Component({
selector: 'app-add-vendor',
templateUrl: './add-vendor.component.html',
styleUrls: ['./add-vendor.component.css']
})
export class AddVendorComponent implements OnInit {
addVendorForm: FormGroup;
checkArr: any;

constructor(public mainservice: MainService, private router: Router) { }

ngOnInit() {
this.addVendorForm = new FormGroup({
'firstName': new FormControl('', [Validators.required, Validators.pattern(/^[a-zA-Z ]*$/i)]),
'lastName': new FormControl('', [Validators.required, Validators.pattern(/^[a-zA-Z ]*$/i)]),
'email': new FormControl('', [Validators.required, Validators.pattern(/^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,3}|[0-9]{1,3})(\]?)$/i)]),
'Customernumber': new FormControl('', [Validators.required, Validators.pattern(/^[^0][0-9]*$/), Validators.maxLength(18), Validators.minLength(8)]),
'password': new FormControl('', [Validators.required, Validators.pattern(/^(?=^.{8,16}$)(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*?[#?!@$%^&*-])(?!.*\s).*$/)]),
'dashboardManagement': new FormControl(''),
'productManagement': new FormControl(''),
'serviceManagement': new FormControl(''),
'giftCardManagement': new FormControl(''),
'notificationManagement': new FormControl(''),
'staticContentManagement': new FormControl(''),
'vendorManagement': new FormControl(''),
'transactionManagement': new FormControl(''),
'referralmanagement': new FormControl('')
});
}

addVendor() {
this.mainservice.showSpinner()
let data = {
'firstName': this.addVendorForm.value.firstName,
'lastName': this.addVendorForm.value.lastName,
'email': this.addVendorForm.value.email,
'mobileNumber': this.addVendorForm.value.Customernumber,
'password': this.addVendorForm.value.password,
'vendorManagement': this.addVendorForm.value.vendorManagement ? this.addVendorForm.value.vendorManagement : false,
'dashboardManagement': this.addVendorForm.value.dashboardManagement ? this.addVendorForm.value.dashboardManagement : false,
'productManagement': this.addVendorForm.value.productManagement ? this.addVendorForm.value.productManagement : false,
'serviceManagement': this.addVendorForm.value.serviceManagement ? this.addVendorForm.value.serviceManagement : false,
'giftCardManagement': this.addVendorForm.value.giftCardManagement ? this.addVendorForm.value.giftCardManagement : false,
'notificationManagement': this.addVendorForm.value.notificationManagement ? this.addVendorForm.value.notificationManagement : false,
'staticContentManagement': this.addVendorForm.value.staticContentManagement ? this.addVendorForm.value.staticContentManagement : false,
'transactionManagement': this.addVendorForm.value.transactionManagement ? this.addVendorForm.value.transactionManagement : false,
'referralManagement': this.addVendorForm.value.referralmanagement ? this.addVendorForm.value.referralmanagement : false
}
this.mainservice.postApi('admin/addVendor', data, 1).subscribe((res: any) => {
if (res.responseCode == 200) {
this.mainservice.hideSpinner()
this.mainservice.successToast(res.responseMessage)
this.router.navigate(['/vendor-management'])
} else {
this.mainservice.hideSpinner()
this.mainservice.errorToast(res.responseMessage)
}
}, (err) => {
this.mainservice.hideSpinner()
this.mainservice.errorToast(err.responseMessage)
})
}

}