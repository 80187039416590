import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-edit-about-us',
  templateUrl: './edit-about-us.component.html',
  styleUrls: ['./edit-about-us.component.css']
})
export class EditAboutUsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
