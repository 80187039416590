import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router'
import { MainService } from 'src/app/provider/main.service';
import { from } from 'rxjs';

@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.css']
})
export class EditComponent implements OnInit {
  contactForm: FormGroup;
  contatUsId: any;
  giftlist: any;
  img: any;
  file: any;
  imageType: any;
  marked = false;
  theCheckbox = false;
  config = {
    uiColor: '#F0F3F4',
    height: '40%',
    enterMode: 2
  };
  imgBase64Path: string;
  constructor(public service: MainService, private activatedroute: ActivatedRoute, private router: Router) { }


  ngOnInit() {
    this.activatedroute.params.subscribe((res: any) => {
      this.contatUsId = res.id
    })
    this.giftList()
    this.editFormValidation()
  }

  giftList() {
    this.service.showSpinner()
    this.service.getApi(`admin/viewHomeScreenContactUs/${this.contatUsId}`, 1).subscribe((res: any) => {
      if (res.responseCode == 200) {
        this.imgBase64Path = res.result.image[0]
        this.theCheckbox = res.result.active
        this.contactForm.patchValue({
          'title': res.result.title,
          'description': res.result.description
        })
        this.service.hideSpinner()
        this.service.successToast(res.responseMessage)
      } else {
        this.service.hideSpinner()
        this.service.errorToast(res.responseMessage)
      }
    }, (error) => {
      this.service.hideSpinner()
    })
  }
  editFormValidation() {
    this.contactForm = new FormGroup({
      'title': new FormControl('', [Validators.required, Validators.maxLength(256)]),
      "description": new FormControl('', Validators.required),
      'image': new FormControl('', Validators.required)
    })
  }

  // -------------------------- edit form submit ------------------------ //
  editContact() {
    if (!this.imgBase64Path) {
      return this.service.errorToast('Please upload image.')
    }
    let data = {
      "_id": this.contatUsId,
      "title": this.contactForm.value.title,
      "description": this.contactForm.value.description,
      "image": this.imgBase64Path,
      'active': this.marked
    }
    console.log(data)
    this.service.showSpinner()
    this.service.putApi('admin/editHomeScreenContactUs', data, 1).subscribe((res: any) => {

      if (res.responseCode == 200) {
        this.service.hideSpinner()
        this.service.successToast(res.responseMessage)
        this.router.navigate(['/contact-banner-list'])
      } else {
        this.service.hideSpinner()
        this.service.errorToast(res.responseMessage)
      }
    }, (error) => {
      this.service.hideSpinner()
    })
  }
  toggleVisibility(e) {
    this.marked = e.target.checked;
  }

  fileChangeEvent(fileInput: any) {
    if (fileInput.target.files && fileInput.target.files[0]) {
      const max_height = 450;
      const max_width = 1920;
      const max_width2 = 1640;
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const image = new Image();
        image.src = e.target.result;
        image.onload = rs => {
          const img_height = rs.currentTarget['height'];
          const img_width = rs.currentTarget['width'];
          console.log(img_height, img_width);
          if (img_height == max_height && (img_width == max_width || img_width == max_width2)) {
            this.imgBase64Path = e.target.result;
          } else {
            this.imgBase64Path = ''
            this.contactForm.controls['image'].reset()
            this.service.errorToast('Please upload image of size 1920 * 450 or 1640 * 450 only.')
          }
        };
      };
      reader.readAsDataURL(fileInput.target.files[0]);
    }
  }

}
