import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MainService } from 'src/app/provider/main.service';

@Component({
  selector: 'app-edit-vendor',
  templateUrl: './edit-vendor.component.html',
  styleUrls: ['./edit-vendor.component.css']
})
export class EditVendorComponent implements OnInit {
  addVendorForm: FormGroup;
  Id: any;

  constructor(private activatedRoute: ActivatedRoute, public mainservice: MainService, private router: Router) { }

  ngOnInit() {
    this.activatedRoute.params.subscribe((res: any) => {
      this.Id = res.id
    })
    this.getVender()
    this.addVendorForm = new FormGroup({
      'firstName': new FormControl('', [Validators.required, Validators.pattern(/^[a-zA-Z ]*$/i)]),
      'lastName': new FormControl('', [Validators.required, Validators.pattern(/^[a-zA-Z ]*$/i)]),
      'email': new FormControl('', [Validators.required, Validators.pattern(/^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,3}|[0-9]{1,3})(\]?)$/i)]),
      'Customernumber': new FormControl('', [Validators.required, Validators.pattern(/^[^0][0-9]*$/), Validators.minLength(8), Validators.maxLength(18)]),
      'dashboardManagement': new FormControl(''),
      'productManagement': new FormControl(''),
      'serviceManagement': new FormControl(''),
      'giftCardManagement': new FormControl(''),
      'notificationManagement': new FormControl(''),
      'staticContentManagement': new FormControl(''),
      'vendorManagement': new FormControl(''),
      'transactionManagement': new FormControl(''),
      'referralmanagement': new FormControl('')

    });
  }

  getVender() {
    this.mainservice.showSpinner()
    this.mainservice.getApi(`admin/viewVendor/${this.Id}`, 1).subscribe((res: any) => {
      if (res.responseCode == 200) {
        this.mainservice.hideSpinner()
        this.addVendorForm.patchValue({
          'firstName': res.result.vendorResult.firstName,
          'lastName': res.result.vendorResult.lastName,
          'email': res.result.vendorResult.email,
          'Customernumber': res.result.vendorResult.mobileNumber,
          'vendorManagement': res.result.vendorResult.permissions.vendorManagement,
          'dashboardManagement': res.result.vendorResult.permissions.dashboardManagement,
          'productManagement': res.result.vendorResult.permissions.productManagement,
          'serviceManagement': res.result.vendorResult.permissions.serviceManagement,
          'giftCardManagement': res.result.vendorResult.permissions.giftCardManagement,
          'notificationManagement': res.result.vendorResult.permissions.notificationManagement,
          'staticContentManagement': res.result.vendorResult.permissions.staticContentManagement,
          'transactionManagement': res.result.vendorResult.permissions.transactionManagement,
          'referralmanagement': res.result.vendorResult.permissions.referralManagement
        })
      } else {
        this.mainservice.hideSpinner()
        this.mainservice.errorToast(res.responseMessage)
      }
    }, (err) => {
      this.mainservice.hideSpinner()
      this.mainservice.errorToast(err.responseMessage)
    })
  }

  editVendor() {
    this.mainservice.showSpinner()
    let data = {
      '_id': this.Id,
      'firstName': this.addVendorForm.value.firstName,
      'lastName': this.addVendorForm.value.lastName,
      'email': this.addVendorForm.value.email,
      'mobileNumber': this.addVendorForm.value.Customernumber,
      'vendorManagement': this.addVendorForm.value.vendorManagement ? this.addVendorForm.value.vendorManagement : false,
      'dashboardManagement': this.addVendorForm.value.dashboardManagement ? this.addVendorForm.value.dashboardManagement : false,
      'productManagement': this.addVendorForm.value.productManagement ? this.addVendorForm.value.productManagement : false,
      'serviceManagement': this.addVendorForm.value.serviceManagement ? this.addVendorForm.value.serviceManagement : false,
      'giftCardManagement': this.addVendorForm.value.giftCardManagement ? this.addVendorForm.value.giftCardManagement : false,
      'notificationManagement': this.addVendorForm.value.notificationManagement ? this.addVendorForm.value.notificationManagement : false,
      'staticContentManagement': this.addVendorForm.value.staticContentManagement ? this.addVendorForm.value.staticContentManagement : false,
      'transactionManagement': this.addVendorForm.value.transactionManagement ? this.addVendorForm.value.transactionManagement : false,
      'referralManagement': this.addVendorForm.value.referralmanagement ? this.addVendorForm.value.referralmanagement : false
    }
    this.mainservice.postApi('admin/editVendor', data, 1).subscribe((res: any) => {
      if (res.responseCode == 200) {
        this.mainservice.hideSpinner()
        this.mainservice.successToast(res.responseMessage)
        this.router.navigate(['/vendor-management'])
      } else {
        this.mainservice.hideSpinner()
        this.mainservice.errorToast(res.responseMessage)
      }
    }, (err) => {
      this.mainservice.hideSpinner()
      this.mainservice.errorToast(err.responseMessage)
    })
  }
}