import { Router } from '@angular/router';
import { MainService } from './../../../provider/main.service';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-add-banner',
  templateUrl: './add-banner.component.html',
  styleUrls: ['./add-banner.component.css']
})
export class AddBannerComponent implements OnInit {
  addBannerForm: FormGroup;
  profile = ''
  config = {
    uiColor: '#F0F3F4',
    height: '50%',
    enterMode: 2

  };

  imgBase64Path: string;

  constructor(public mainService: MainService, private router: Router) { }

  ngOnInit() {
    this.addBannerForm = new FormGroup({
      'firstName': new FormControl('', [Validators.required, Validators.pattern(/^[a-zA-Z ]*$/i)]),
      "title": new FormControl('', Validators.required),
      "bannerFile": new FormControl('', Validators.required)
    });
  }

  // -------------------- add form submit ----------------------- //
  addNewBanner() {
    if (!this.imgBase64Path) {
      return this.mainService.errorToast('Please upload image.')
    }

    let url = 'admin/addBanner'

    let data =
    {
      'name': this.addBannerForm.value.firstName,
      'title': this.addBannerForm.value.title,
      'image': this.imgBase64Path
    }
    this.mainService.showSpinner();
    this.mainService.postApi(url, data, 1).subscribe((res: any) => {
      console.log("AddBanner  response ==>", res)
      if (res.responseCode == 200 && res.result) {

        this.mainService.hideSpinner();
        this.mainService.successToast(res.responseMessage)
        this.router.navigate(['/banner-management'])
        this.addBannerForm.reset()


      } else {
        this.mainService.hideSpinner();
        this.mainService.errorToast(res.responseMessage)
      }
      error => {
        this.mainService.hideSpinner();
      }
    })
  }
  
  fileChangeEvent(fileInput: any) {
    if (fileInput.target.files && fileInput.target.files[0]) {
      const max_height = 450;
      const max_width = 1920;
      const max_width2 = 1640;
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const image = new Image();
        image.src = e.target.result;
        image.onload = rs => {
          const img_height = rs.currentTarget['height'];
          const img_width = rs.currentTarget['width'];
          console.log(img_height, img_width);
          if (img_height == max_height && (img_width == max_width || img_width == max_width2)) {
            this.imgBase64Path = e.target.result;
          } else {
            this.imgBase64Path = ''
            this.addBannerForm.controls['bannerFile'].reset()
            this.mainService.errorToast('Please upload image of size 1920 * 450 or 1640 * 450 only.')
          }
        };
      };
      reader.readAsDataURL(fileInput.target.files[0]);
    }
  }
}
