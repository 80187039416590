import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MainService } from 'src/app/provider/main.service';

@Component({
  selector: 'app-view-sub-admin',
  templateUrl: './view-sub-admin.component.html',
  styleUrls: ['./view-sub-admin.component.css']
})
export class ViewSubAdminComponent implements OnInit {

  editform: FormGroup;
  array: any = [];

  permissionArr: any = [
    "dashboardManagement",
    "vendorManagement",
    "subAdminManagement",
    "productManagement",
    "serviceManagement",
    "giftCardManagement",
    "transactionManagement",
    "notificationManagement",
    "staticContentManagement",
    "referralManagement",
  ];
  privilage: any = {
    "dashboardManagement" : false,
    "vendorManagement" : false,
    "subAdminManagement" : false,
    "productManagement" : false,
    "serviceManagement" : false,
    "giftCardManagement": false,
    "transactionManagement" : false,
    "notificationManagement" : false,
    "staticContentManagement" : false,
    "referralManagement" : false,

  };

  name: any = [];
  check: any = [];

  index = 0;
  id : any
  constructor(public service: MainService, private route: Router,private activated : ActivatedRoute) {
    this.activated.queryParams.subscribe((res)=>{
      this.id = res.id
    })
  }

  ngOnInit() {
    for (let i of this.permissionArr) {
      this.name[this.index] = String(i).split(/(?=[A-Z])/).join(" ");
      this.index++;
    }
    this.editform = new FormGroup({
      name: new FormControl("", [
        Validators.required,
        Validators.pattern(/^[a-zA-Z ]*$/i),
      ]),
      lastName: new FormControl("", [
        Validators.required,
        Validators.pattern(/^[a-zA-Z ]*$/i),
      ]),
      email: new FormControl("", [Validators.required, Validators.email]),
      phone: new FormControl("", [
        Validators.required,
        Validators.pattern(/^[^0][0-9]*$/),
      ]),
      password: new FormControl("", [
        Validators.required,
        Validators.pattern(
          /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,16}$/
        ),
      ]),
    });
    this.viewSubAdmin()
  }
  viewSubAdmin() {
    let url = "admin/viewSubAdmin/"+this.id;
    let i = 0
    

  
    
    
    this.service.showSpinner();
    this.service.getApi(url, 1).subscribe(
      (res) => {
        if (res.responseCode == 200) {
          this.editform.patchValue({
            name : res.result.subAdminRes.firstName,
            lastName : res.result.subAdminRes.lastName,
            email : res.result.subAdminRes.email,
            phone : res.result.subAdminRes.mobileNumber
          })
          for(let item of this.permissionArr){
            console.log(res.result.subAdminRes.permissions[item]);
            this.check[i] = res.result.subAdminRes.permissions[item]
            i++
          }
          this.service.hideSpinner();
          this.service.successToast(res.responseMessage);
        } else {
          this.service.hideSpinner();
          this.service.errorToast(res.responseMessage);
        }
      },
      (err) => {
        this.service.hideSpinner();
        this.service.errorToast(err);
      }
    );
  }

  checkPerm(permName, ch) {
    console.log(permName, ch);
    this.privilage[permName] = ch
    console.log(this.privilage);
  }
}
