import { Component, OnInit } from '@angular/core';
import { MainService } from 'src/app/provider/main.service';
import { ApiUrls } from 'src/app/config/api-urls/api-urls';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  dashboardData: any;
  totalUser: any;
  totalPractioner: any;
  totalService: any;
  totalProduct: any;
  totalCorporate: any;
  totalVendor: any;

  constructor(public mainService: MainService) { }

  ngOnInit() {
    this.getUser();
    this.getPractioner();
    this.getService();
    this.getProducts();
    this.getCorporate();
    this.getVendorList()
  }

  // total users
  getUser() {
    this.mainService.showSpinner();
    let data = {

    }
    this.mainService.postApi('admin/listUsers', '', 1).subscribe((res: any) => {

      if (res.responseCode == 200) {
        this.mainService.hideSpinner();
        this.totalUser = res.result.total
      }
      else {
        this.mainService.hideSpinner()
        this.mainService.errorToast(res.responseMessage)
      }
    }, (error: any) => {
      this.mainService.hideSpinner();
    })
  }

  // total corporate customer
  getCorporate() {
    this.mainService.showSpinner();
    let data = {}
    this.mainService.postApi('admin/corporateList', '', 1).subscribe((res: any) => {

      if (res.responseCode == 200) {
        this.mainService.hideSpinner();
        this.totalCorporate = res.result.total;
      }
      else {
        this.mainService.hideSpinner()
        this.mainService.errorToast(res.responseMessage)
      }

    }, (error: any) => {
      this.mainService.hideSpinner();
    })
  }
  // total practioner
  getPractioner() {
    this.mainService.showSpinner();
    let data = {

    }
    this.mainService.postApi('admin/practitionerList', '', 1).subscribe((res: any) => {

      if (res.responseCode == 200) {
        this.mainService.hideSpinner();
        this.totalPractioner = res.result.total
      }
      else {
        this.mainService.hideSpinner()
        this.mainService.errorToast(res.responseMessage)
      }
    }, (error: any) => {
      this.mainService.hideSpinner();
    })
  }

  // total service
  getService() {
    this.mainService.showSpinner();
    let data = {

    }
    this.mainService.postApi('admin/serviceList', '', 1).subscribe((res: any) => {

      if (res.responseCode == 200) {
        this.mainService.hideSpinner();
        this.totalService = res.result.total

      }
      else {
        this.mainService.hideSpinner()
        this.mainService.errorToast(res.responseMessage)
      }

    }, (error: any) => {
      this.mainService.hideSpinner();
    })
  }

  // total products
  getProducts() {
    this.mainService.showSpinner();
    let data = {

    }
    this.mainService.postApi('admin/productList', '', 1).subscribe((res: any) => {

      if (res.responseCode == 200) {
        this.mainService.hideSpinner();
        this.totalProduct = res.result.total


      }
      else {
        this.mainService.hideSpinner()
        this.mainService.errorToast(res.responseMessage)
      }
    }, (error: any) => {
      this.mainService.hideSpinner();
    })
  }


  getVendorList() {
    this.mainService.postApi('admin/vendorList', '', 1).subscribe((res: any) => {
      if (res['responseCode'] == 200) {
        this.totalVendor = res.result.total
      } else {
        this.mainService.errorToast(res.responseMessage)
      }
    }, (err) => {
      this.mainService.errorToast(err.responseMessage)
    })
  }

}
